import _objectSpread from "/Users/lianxiang/star/htcode/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var _this6 = this;
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { Edit } from '@element-plus/icons-vue';
import SearchSelect from '../../components/common/Search';
import { courseSearchList } from '../../assets/js/data';
import CommonTable from '../../components/common/CommonTable';
// import {coursestructureOperation,sendThis} from '../../data/course/courseData'
import { sendThis } from '../../data/help/helpData';
import { helpList } from '../../data/courseTable';
import { courseStructureDrawer } from '../../data/courseDrawer';
import CommonDrawer from '../../components/common/CommonDrawer';
import EditorHelpContainer from '../../components/popup/EditorHelpContainer'; //图文
//图标

export default {
  name: "CourseStructure",
  data: function data() {
    return {
      // 筛选
      filter: [{
        placeholder: '分类状态',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '正常'
        }, {
          id: '-1',
          name: '禁用'
        }]
      }, {
        placeholder: '分类名称',
        value: '',
        type: 'text',
        list: []
      }],
      isEdit: false,
      //默认是添加
      drawerKey: 0,
      //抽屉key
      courseStructureDrawer: courseStructureDrawer,
      helpList: helpList,
      structureDrop: [],
      //下拉结构
      structurelevelDrop: [],
      //标准下拉结构
      userInfo: this.Ls.getParseToken('nof_userInfo'),
      //获取用户数据
      tableKey: 1,
      //避免数据更新表格视图不更新
      tableData: [],
      total: 0,
      //页数总数
      currentPage: parseInt(this.$route.query.currentPage ? this.$route.query.currentPage : 1),
      // 当前页数
      pageSize: parseInt(this.$route.query.pageSize ? this.$route.query.pageSize : 10),
      //每页个数
      courseSearchList: courseSearchList,
      // courseData:JSON.parse(localStorage.getItem("course")),
      state: true,
      //课程状态
      Edit: Edit,
      //图标
      imageUrl: "",
      //课程图标
      resetData: null,
      //结构数据的初始值 永远不变，用来还原用的
      // localCourseData:JSON.parse(localStorage.getItem("course")),//当前课程额数据
      structureData: {
        //添加结构数据
        userId: this.Ls.getParseToken('nof_userInfo').userId,
        roleId: this.Ls.getParseToken('nof_userInfo').roleId,
        name: "",
        //阶段
        stdlevelId: "",
        //标准课程结构层级ID
        stdlevelValue: "",
        //value
        pid: "",
        //父级ID
        haveResource: 0,
        //本层结构是否带课程资源：0无 1有
        description: "",
        //描述
        rank: 1,
        //排列顺序
        state: 1 //状态：1正常 -1禁用
      },

      //添加结构、修改结构的数据

      //添加修改资源
      formFile: {
        userId: this.Ls.getParseToken('nof_userInfo').userId,
        roleId: this.Ls.getParseToken('nof_userInfo').roleId,
        name: '',
        //阶段
        topictypeId: '',
        //分类id
        rank: 1,
        //排序
        state: 1,
        //状态：1正常 -1禁用
        isTop: 0,
        //是否置顶资源 1：置顶 0：不置顶
        content: "" //图文内容
      },

      isShow: false
    };
  },
  watch: {
    '$route': 'initData'
  },
  components: {
    SearchSelect: SearchSelect,
    CommonTable: CommonTable,
    CommonDrawer: CommonDrawer,
    EditorHelpContainer: EditorHelpContainer
  },
  mounted: function mounted() {
    //发送当前实例
    sendThis(this);
    //设置面包屑
    this.$emit('navigation', this.navData);
    //获取分类的下拉框
    // this.getResourcetypeDrop();
  },
  created: function created() {
    //拿到初始值
    this.resetData = _objectSpread({}, this.structureData);

    // this.initDataJs();
    this.initData();
  },
  methods: {
    //编辑图文
    editHelp: function editHelp(row) {
      this.currentRow = row;
      console.log(row);
      //图文编辑
      this.formFile = _objectSpread({}, this.currentRow);
      this.formFile.id = this.currentRow.id;
      console.log(this.formFile);
      this.showEditor(1);
    },
    //是否置顶
    changeTop: function changeTop(row) {
      console.log(row);
      var json = {
        userId: this.Ls.getParseToken('nof_userInfo').userId,
        roleId: this.Ls.getParseToken('nof_userInfo').roleId,
        id: row.id,
        state: row.state,
        name: row.name,
        content: row.content,
        topictypeId: row.topictypeId,
        isTop: row.isTop,
        rank: row.rank
      };
      if (json.isTop == 1) {
        json.isTop = 0;
      } else {
        json.isTop = 1;
      }
      //修改状态
      this.helpEditHelp(json);
    },
    //编辑
    helpEditHelp: function helpEditHelp(postData) {
      var _this = this;
      this.service.axiosUtils({
        requestName: "helpEditHelp",
        data: postData,
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            //初始化有就调用
            // this.$refs.childRef.initFn && this.$refs.childRef.initFn();
            // this.$refs.childRef.dialogFormVisible=false;
            _this.$toast.success(res.message);
            _this.cancelEditor();
            _this.initData();
          } else {
            _this.$toast.error(res.message);
          }
        }
      });
    },
    //获取分类下拉
    getResourcetypeDrop: function getResourcetypeDrop() {
      var _this2 = this;
      this.service.axiosUtils({
        requestName: 'topictypeGetAll',
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId
        },
        sucFn: function sucFn(res) {
          _this2.resourcetypeDrop = res.data;
          // this.courseStructureDrawer.list[3].drop.list=this.structureDrop;
          /*          this.courseStructureDrawer.list[3].drop.list.push({
                      id:0,
                      name:"无",
                      value:0
                    })*/
          // this.drawerKey++;
        }
      });
    },
    //取消图文
    cancelEditor: function cancelEditor() {
      this.isShow = false;
      //数据恢复初始化
      this.formFile = {
        userId: this.Ls.getParseToken('nof_userInfo').userId,
        roleId: this.Ls.getParseToken('nof_userInfo').roleId,
        name: '',
        //阶段
        topictypeId: '',
        //分类id
        rank: 1,
        //排序
        state: 1,
        //状态：1正常 -1禁用
        isTop: 0,
        //是否置顶资源 1：置顶 0：不置顶
        content: "" //图文内容
      };
    },
    //图文保存
    saveBack: function saveBack(json) {
      var _this3 = this;
      var json = json || {};
      var requestName = "";
      this.formFile.content = json.content;
      this.formFile.name = json.name;
      var postData = _objectSpread({}, this.formFile);
      delete postData.url;
      if (this.formFile.id) {
        //编辑
        requestName = "helpEditHelp";
      } else {
        //保存
        requestName = "helpAddHelp";
      }
      this.service.axiosUtils({
        requestName: requestName,
        data: postData,
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            //初始化有就调用
            // this.$refs.childRef.initFn && this.$refs.childRef.initFn();
            // this.$refs.childRef.dialogFormVisible=false;
            _this3.$toast.success(res.message);
            _this3.cancelEditor();
            _this3.initData();
          } else {
            _this3.$toast.error(res.message);
          }
        }
      });
      // this.$refs.editor.cancelClose();
    },
    //显示添加图文内容弹框
    showEditor: function showEditor(isEdit) {
      if (isEdit == 1) {
        //编辑
      } else {
        //添加
        // this.formFile.restypeId="";
        this.formFile.name = "";
      }
      this.isShow = true;
    },
    resetDataFn: function resetDataFn() {
      //弹框的数据初始化
      this.structureData = _objectSpread({}, this.resetData);
    },
    //确认弹框的内容
    dialogContent: function dialogContent(json, row) {
      json.content = "确定删除" + row.name + "吗？";
      return json;
    },
    initDataJs: function initDataJs() {
      // this.coursestructureOperation[1].clickFn();
    },
    //删除结构
    deleteFn: function deleteFn(row) {
      var _this4 = this;
      this.service.axiosUtils({
        requestName: 'helpDeleteHelp',
        data: {
          id: row.id
        },
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            _this4.centerDialogVisible = false;
            _this4.$toast.success(res.message);
            _this4.initData();
          } else {
            _this4.$toast.error(res.message);
          }
        }
      });
    },
    //编辑结构
    edit: function edit(row) {
      var _this5 = this;
      this.resetDataFn();
      this.isEdit = true;
      this.showAddCourseDrawer('edit');
      /*      this.isEdit=true;
            this.showAddCourseDrawer();
            this.resetDataFn();*/
      setTimeout(function () {
        _this5.structureData = _objectSpread({}, row);
        _this5.structureData.pid = row.parent ? row.parent.id : 0;
        _this5.structureData.roleId = _this5.Ls.getParseToken('nof_userInfo').roleId;
      }, 0);
    },
    //显示添加课程结构定义抽屉弹框
    showAddCourseDrawer: function showAddCourseDrawer(type) {
      if (type == "add") {
        this.isEdit = false;
      } else if (type == "edit") {
        this.isEdit = true;
      } else {
        this.isEdit = false;
      }
      //显示抽屉弹框
      this.$refs.commonDrawer.drawer = true;
    },
    handleAvatarSuccess: function handleAvatarSuccess(response, uploadFile) {
      _this6.imageUrl = URL.createObjectURL(uploadFile.raw);
    },
    //过滤表格的是否有资源
    formatter: function formatter(row, column) {
      var isHava = "否";
      if (row.haveResource == 0) {
        isHava = "否";
      } else {
        isHava = "是";
      }
      return isHava;
    },
    nextPage: function nextPage(t) {
      switch (t) {
        case 1:
          this.$router.push({
            path: "/course/edit/structure"
          });
          break;
      }
    },
    //修改状态
    changeState: function changeState(value) {
      var _this7 = this;
      this.courseData.roleId = this.userInfo.roleId;
      var postData = {
        userId: this.userInfo.userId,
        roleId: this.userInfo.roleId,
        id: value.id
      };
      if (value.state == 1) {
        postData.state = -1;
      } else {
        postData.state = 1;
      }
      this.service.axiosUtils({
        requestName: 'helpChangeState',
        data: postData,
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            _this7.$toast.success(res.message);
          } else {
            _this7.$toast.error(res.message);
          }
          _this7.initData();
        }
      });
    },
    //初始化获取数据
    initData: function initData() {
      var _this8 = this;
      this.service.axiosUtils({
        requestName: 'helpGetList',
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          // courseId:this.courseData.id,
          pageSize: this.$route.query.pageSize ? this.$route.query.pageSize : 10,
          pageIndex: this.$route.query.currentPage ? this.$route.query.currentPage : 1
        },
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            _this8.tableData = res.data.data;
            _this8.total = res.data.total;
            _this8.tableKey++;
          } else {
            _this8.$toast.error(res.message);
          }
        }
      });

      //获取帮助中心的下拉
      this.getResourcetypeDrop();
    },
    // 页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      // 获取最大页数
      var pageMax = Math.ceil(this.total / this.pageSize);
      // 判断跳转页数是否大于最大页数，如果大于，跳转页数就等于最大页数
      if (this.currentPage > pageMax) {
        this.currentPage = pageMax;
      }
      this.$router.replace({
        path: this.$route.path,
        query: {
          currentPage: this.currentPage,
          pageSize: page
        }
      });
    },
    // 页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.$router.replace({
        path: this.$route.path,
        query: {
          currentPage: this.currentPage,
          pageSize: this.$route.query.pageSize ? this.$route.query.pageSize : 10
        }
      });
    },
    //添加课程结构定义确定
    confirmClick: function confirmClick(valid, formRef, formData) {
      var _this9 = this;
      if (valid) {
        var requestName = "";
        if (this.isEdit) {
          requestName = "editStructureDefinition";
        } else {
          //添加
          requestName = "addStructureDefinition";
        }
        console.log(this.structureData);
        this.service.axiosUtils({
          requestName: requestName,
          data: this.structureData,
          sucFn: function sucFn(res) {
            if (res.status == 1) {
              _this9.drawer = false;
              _this9.$toast.success(res.message);
              _this9.$refs.commonDrawer.drawer = false;
              formRef.resetFields();
              _this9.initData();
            } else {
              _this9.$toast.error(res.message);
            }
          }
        });
      }
    }
  }
};